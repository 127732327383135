import React from 'react';
import Layout from "../components/layout"
import MilkLink from "../components/milk-link"
import SEO from "../components/seo"
import A80 from "../animations/a80"
import A160 from "../animations/a160"
import A400 from "../animations/a400"

export default ({ pageContext: { pages, enTexts, langs } }) => (
    <Layout pages={pages} lang="en" hideFooter={true} texts={enTexts} langs={langs}>
        <SEO title="404" />
        <div className="layer-404">
            <A160 className="error-liquid-1" />
            <A400 className="error-liquid-2" />
            <A80 className="error-liquid-3" />
            <div className="small-wrap">
                <div className="group group-3">
                    <div className="left">
                        <h1 dangerouslySetInnerHTML={{__html: enTexts.not_found_title }} />
                        <div className="layer-label" dangerouslySetInnerHTML={{__html: enTexts.not_found_section }} />
                        <div className="h3" dangerouslySetInnerHTML={{__html: enTexts.not_found_text }} />
                        <MilkLink to="/en" className="btn">{enTexts.not_found_btn}</MilkLink>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);
